<template>
  <div class='login'>
    <div class="qr-show" @mouseenter="qrcodeShow=true" @mouseleave="qrcodeShow=false">
      <div class="APP">{{$t('Download app')}}</div>
      <div v-if="qrcodeShow">
        <div class="app-down-qr" v-if="qrcodeParam">
          <qr-code :param="qrcodeParam"></qr-code>
        </div>
      </div>
    </div>

    <el-radio-group v-model="loginWay" @change="change" size="mini" style="position: fixed;z-index: 99;right: 130px;top: 13px;">
      <el-radio-button border label="merchantLogin">{{ $t('organization login') }}</el-radio-button>
      <el-radio-button border label="customerLogin">{{ $t('member login') }}</el-radio-button>
    </el-radio-group>
    <lang />
    <div class="hazy">
      <el-image calss="logo-max" :src="require('@/assets/image/Logo/logo2.png')" fit="fit"></el-image>
    </div>
    <div :class="[loginParty == 'merchantLogin' ? 'login-back1' : 'login-back2']"></div>
    <div class="login-page" :class="[isResetPassword]">
      <div class="login-block" ref="loginblock">
        <el-form v-show="!isResetPassword" ref="loginForm" :model="form" :rules="rules" label-width="20px">
          <h3>BNIF AFUWA</h3>
          <el-form-item :label="$t('account')" prop="account" class="el-form-item_my">
            <template slot="label">
              <span><i class="iconfont iconzhanghu" /></span>
            </template>
            <el-input size="medium" v-model="form.account" :placeholder="$t('loginAccount')" autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('password')" prop="password" class="el-form-item_my">
            <template slot="label">
              <span><i class="iconfont iconziyuan" /></span>
            </template>
            <el-input size="medium" v-model="form.password" type="password" :placeholder="$t('loginPassword')" show-password autocomplete="off"></el-input>
          </el-form-item>
          <!-- 机构登录显示短信验证码 -->
          <el-form-item v-if="loginParty == 'merchantLogin'" :label="$t('messageAuthenticationCode')" prop="code" class="el-form-item_my mini">
            <template slot="label">
              <span><i class="iconfont iconyanzhengma" /></span>
            </template>
            <el-input size="medium" v-model="form.code" :placeholder="$t('verificationCode')" maxlength="4" @keyup.enter.native="merchantLogin('loginForm')" />
            <!-- 生产环境使用 -->
            <!-- <el-button :loading="isLoading" class="image-validate-code" :disabled="surplusTime!=0||!form.account">
              <span v-if="!surplusTime" @click="sendVC">{{$t('Send SMS verification code')}}</span>
              <span v-else>{{$t('send it again')+`(${surplusTime})`}}</span>
            </el-button> -->
            <!-- 开发环境使用 -->
            <el-image :src="imageValidateCode" class="image-validate-code" @click="getImageValidateCode" />
          </el-form-item>

          <!-- 客户登录显示图形验证码 -->
          <el-form-item v-if="loginParty == 'customerLogin'" :label="$t('messageAuthenticationCode')" prop="imgCode" class="el-form-item_my mini">
            <template slot="label">
              <span><i class="iconfont iconyanzhengma" /></span>
            </template>
            <el-input size="medium" v-model="form.imgCode" :placeholder="$t('verificationCode')" maxlength="4" @keyup.enter.native="customerLogin('loginForm')" />
            <el-image :src="imageValidateCode" class="image-validate-code" @click="getImageValidateCode" />
          </el-form-item>

          <el-form-item class="login-btn">
            <el-button v-if="loginParty == 'merchantLogin'" size="medium" type="primary" @click="merchantLogin('loginForm')" :loading="isLoading">
              {{ $t('login') }}
            </el-button>
            <el-button v-else size="medium" type="primary" @click="customerLogin('loginForm')" :loading="isLoading">
              {{ $t('login') }}</el-button>
          </el-form-item>

          <div v-if="loginParty=='customerLogin'" class="reset-passwords">
            <a @click="onResetPassword">{{$t('forget the password')}}</a>
          </div>
        </el-form>
        <!-- :rules="rulesReset"  -->
        <el-form v-show="isResetPassword" ref="resetForm" :model="resetForm" :rules="rulesReset" hide-required-asterisk label-width="20px" class="reset-form">
          <h3>BNIF AFUWA</h3>
          <el-form-item :label="$t('account')" class="el-form-item_my" prop="account">
            <template slot="label">
              <span><i class="iconfont iconzhanghu" /></span>
            </template>
            <el-input size="medium" v-model="resetForm.account" :placeholder="$t('loginAccount')" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('phone')" class="el-form-item_my" prop="phone">
            <template slot="label">
              <span><i class="el-icon-mobile-phone"></i></span>
            </template>
            <el-input size="medium" v-model="resetForm.phone" :placeholder="$t('phone')" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('smsCode')" class="el-form-item_my" prop="smsCode">
            <template slot="label">
              <span><i class="iconfont iconyanzhengma"></i></span>
            </template>
            <el-input size="medium" v-model="resetForm.smsCode" :placeholder="$t('messageAuthenticationCode')" autocomplete="off" />
            <el-button type="primary" @click.prevent="sendResetCode()" class="send-code" :disabled="resetCountDown>0" :loading="isSending">
              <template v-if="resetCountDown">{{$t('send it again')}}({{resetCountDown}})</template>
              <template v-else>{{$t('Send verification code')}}</template>
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('newPassword')" class="el-form-item_my" prop="newPassword">
            <template slot="label">
              <span><i class="iconfont iconziyuan"></i></span>
            </template>
            <el-input size="medium" v-model="resetForm.newPassword" :placeholder="$t('newPassword')" autocomplete="off" />
          </el-form-item>
          <el-form-item class="login-btn">
            <el-button size="medium" type="primary" @click="onSubmitNewPassword('resetForm')" :loading="isSubmit">
              {{ $t('submit') }}
            </el-button>
            <el-button size="medium" plain type="primary" @click="onCancelReset()" :loading="isSubmit">
              {{ $t('cancel') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="guawazi">
      <!-- @click="dialogTableVisible = true"  -->
      <a href="privacy-policy.html">{{ $t('Privacy Policy') }}</a>
      <a href="about-us.html">{{ $t('about us') }}</a>
      <a href="services-us.html">{{ $t('services us') }}</a>
      <a href="contact-us.html">{{ $t('contact us') }}</a>
    </div>

    <el-dialog :title="$t('Privacy Policy')" :visible.sync="dialogTableVisible" top="5vh">
      <privacy-policy />
    </el-dialog>

    <!-- <div class="app-down-qr" v-if="qrcodeParam">
      <qr-code :param="qrcodeParam"></qr-code>
    </div> -->
  </div>
</template>
<script>
import lang from "@/components/language";
import privacyPolicy from "@/components/login/PrivacyPolicy.vue";
import qrCode from '../../components/QRcode.vue'
import storage from '@/assets/uilt/storage';
import login from '@/api/login';
export default {
  components: { lang, qrCode, privacyPolicy },
  props: ['loginParty'],
  data () {
    var account = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('The login account cannot be empty')));
      }
      // if (value.length < 5) {
      //   return callback(new Error($t('账户长度不能小于5位')));
      // }
      callback();
    };
    var password = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error('登录密码不能为空'));
      // }
      if (!value || value.length < 4) {
        return callback(new Error(this.$t('Password length must not be less than 6 characters')));
      }
      callback();
    };
    var securityCode = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error('验证码不能为空'));
      // }
      if (!value || value.length != 4) {
        return callback(new Error(this.$t('Captcha length is not equal to 4')));
      }
      callback();
    };

    return {
      //登录方向，个人登录或机构登录
      loginWay: null,

      form: {},
      rules: {
        account: [{ validator: account, trigger: 'blur' }],
        password: [{ validator: password, trigger: 'blur' }],
        code: [{ validator: securityCode, trigger: 'blur' }],
        imgCode: [{ validator: securityCode, trigger: 'blur' }],
        checked: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }]
      },

      imageValidateCode: '/back/login/vercode',
      Send: false,
      isLoading: false,
      surplusTime: 0,
      dialogTableVisible: false,

      // 重置密码
      isSending: false, //发送短信验证码加载中
      isSubmit: false,  //新密码提交中
      resetCountDown: 0,//60秒倒计时
      resetForm: {},    //重置密码FORM
      isResetPassword: null, //反转动画css
      rulesReset: {
        account: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        newPassword: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      },

      //生成二维码的参数
      qrcodeParam: null,
      qrcodeShow: false
    };
  },
  computed: {},
  methods: {
    change (value) {
      if (value == this.$route.name) return;
      this.$router.push({ name: value });
      this.$notify({ type: "success", message: this.$t(value), duration: 3000 });

      this.setQRCodeUr();
    },
    /**机构登录 */
    merchantLogin (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.form.code = _this.form.code.toLowerCase();
          _this.isLoading = true;
          login.orgLogin({
            param: _this.form,
            success: function (res) {
              if (res.code == 200) {
                setTimeout(() => {
                  _this.$router.push({ name: "layout" });
                  _this.isLoading = false;
                }, 1000);

                _this.$store.dispatch('system/setInformationPerfect', false)
                storage.local.set('loginName', { name: _this.loginParty });
              }
              else {
                _this.getImageValidateCode();
                _this.$message.warning(_this.$t(res.msg));
                _this.isLoading = false;
              }
            }
          })
        }

        return false;
      });
    },
    /**客户登录 */
    customerLogin (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.form.imgCode = _this.form.imgCode.toUpperCase();
          _this.isLoading = true;
          login.customerLogin({
            param: _this.form,
            success: function (res) {
              if (res.code == 200) {
                setTimeout(() => {
                  _this.$router.push({ name: "layout" });
                  _this.isLoading = false;
                }, 500);
                storage.local.set('loginName', { name: _this.loginParty });
              }
              else {
                _this.getImageValidateCode();
                _this.$message.warning(_this.$t(res.msg));
                _this.isLoading = false;
              }
            }
          })
        }
        return false;
      });
    },
    /**更新图形验证码 */
    getImageValidateCode () {
      this.imageValidateCode = '/back/login/vercode?t=' + new Date().getTime();
      // this.$nextTick(function () { this.$message.success('图形验证码切换成功'); });
    },
    /**发送短信验证码 */
    sendVC () {
      let _this = this;
      login.getSmsCode({
        param: { account: _this.form.account }, success: res => {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg));
            return;
          }
          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0) clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t('SMS verification code sent successfully'));
        }
      })
    },
    /**切换到重置密码页面 */
    onResetPassword () {
      if (!this.isResetPassword) {
        this.$set(this, 'isResetPassword', 'reset-password-page')
      }
      else {
        this.$set(this, 'isResetPassword', '')
      }
    },
    /**取消重置密码（切换回登陆页面），在提交之前可用 */
    onCancelReset () {
      this.$set(this, 'isResetPassword', '');
      this.resetForm = {};
    },
    /**发送重置密码短信验证码 */
    sendResetCode () {
      if (!this.resetForm.account || !this.resetForm.phone) {
        this.$message.warning(this.$t('Please enter your login account and mobile phone number'));
        return;
      }
      this.isSending = true;

      login.getSmsByPhone({
        param: { account: this.resetForm.account, phone: this.resetForm.phone },
        success: res => {
          this.isSending = false;
          if (res.code != 200) {
            this.$message.error(this.$t(res.msg || 'operationFailure'));
            return;
          }
          this.resetCountDown = 60;
          this.$message.success(this.$t(res.msg || 'SMS verification code sent successfully'));

          let interval = setInterval(() => {
            this.resetCountDown -= 1;
            if (this.resetCountDown <= 0)
              clearInterval(interval)
          }, 1000);
        }
      })
    },
    /**提交新密码 */
    onSubmitNewPassword (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        this.isSubmit = true;
        login.forgetThePassword({
          param: this.resetForm,
          success: res => {
            if (res.code == 200) {
              this.onCancelReset();
              this.$message.success(this.$t('password changed successfully'))
            }
            else {
              this.$message.error(this.$t(res.msg || 'password changed successfully'))
            }

            this.isSubmit = false;
          }
        })
      })
    },
    setQRCodeUr () {
      if (this.loginParty == 'merchantLogin') {//机构
        // this.qrcodeParam = 'http://199.223.115.141/sys_cpanel/niorg.apk'
        // this.qrcodeParam = location.origin + '/nigerresource/niorg.apk'
        this.qrcodeParam = location.origin + '/sys_cpanel/niorg.apk'
      }
      else if (this.loginParty == 'customerLogin') {//个人
        // this.qrcodeParam = 'http://199.223.115.141/sys_cpanel/nicus.apk'
        // this.qrcodeParam = location.origin + '/nigerresource/nicus.apk'
        this.qrcodeParam = location.origin + '/sys_cpanel/nicus.apk'
      }
    }
  },
  mounted () {
    this.$set(this, "loginWay", this.loginParty);
    this.setQRCodeUr();
  }
}
</script>
<style  lang='less'>
.login {
  .hazy {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #08080821;

    .el-image {
      width: 400px;
      height: 120px;
    }
  }

  .login-back1,
  .login-back2,
  .login-back3 {
    height: 100vh;
    background: url("../../assets/image/login/login.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .login-back2 {
    background: url("../../assets/image/login/loginc.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .login-back3 {
    background: url("../../assets/image/login/login3.jpg");
  }

  .login-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // transition: 1000ms;
    animation: loginSystem 500ms;
    z-index: 10;

    .login-block {
      width: 380px;
      margin: calc((100vh - 470px) / 2) auto;

      .logo {
        text-align: center;
        color: @white;
        font-size: 24px;

        h3 {
          margin-top: 0;
        }
      }

      .el-form {
        background: @white;
        padding: 25px 25px 20px;
        border-radius: 5px;

        h3 {
          text-align: center;
          font-size: 32px;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 30px;
          color: #666666;
        }
      }

      .el-form-item_my {
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 32px;

        .el-form-item__label {
          margin-left: 20px;
        }

        .el-input {
          width: 260px;
        }

        .el-input__inner {
          background: transparent;
          border: none;
        }
      }
      .el-form-item_my.mini {
        width: 160px;

        .el-input {
          width: 120px;
        }
        .image-validate-code {
          position: absolute;
          top: 0;
          right: -170px;
          width: 165px;
          bottom: 0;
          border-radius: 5px;
        }
      }
      .login-btn {
        width: 100%;

        .el-form-item__content {
          margin: 0 !important;
          .el-button {
            width: 100%;
            height: 48px;
          }
        }
      }
    }
  }
  .reset-password-page {
    animation: resetPassword 500ms;
    .reset-form {
      h3 {
        margin-bottom: 25px !important;
      }
      .el-form-item {
        margin-bottom: 20px;
      }
      .send-code {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .login-btn .el-form-item__content {
        display: flex;
        .el-button {
          flex: 1;
        }
      }
    }
  }
  @keyframes loginSystem {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes resetPassword {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

  .el-form-item__label {
    float: left;
  }
  .el-form-item__content {
    margin-left: 55px !important;
  }
  .guawazi {
    position: fixed;
    bottom: 15px;
    width: 100%;
    text-align: center;
    color: @white;
    z-index: 100;
    a {
      cursor: pointer;
      color: @white;
      padding: 0 10px;
    }
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
  .qr-show {
    position: fixed;
    z-index: 99;
    right: 420px;
    top: 18px;
    width: 170px;
    cursor: default;
    .APP:nth-child(1) {
      text-align: right;
      margin-bottom: 10px;
    }
    .app-down-qr {
      // position: fixed;
      // top: 30%;
      // left: 20%;
      padding: 20px;
      // z-index: 9999;
      background: @white;
      border-radius: 10px;
      text-align: center;
    }
  }
  .reset-passwords {
    text-align: center;
    a {
      color: @primary;
      cursor: pointer;
    }
  }
}
</style>